define([], function () {
    'use strict';
    const getSpaceIndexesForPhoneFormat = (
        val,
        phoneFormat,
        isValueFormatted = true
    ) => {
        const updatedSpaceInfo = Array.from(phoneFormat)
            .reduce((agg, curr, idx) => curr === '-' ? [...agg, idx] : agg, [])
            .filter((spaceIdx, index) => isValueFormatted ?
                spaceIdx <= val.length :
                spaceIdx <= val.length + index);

        return updatedSpaceInfo;
    };

    return {
        formatPhoneNumber(unformattedValue, phoneFormat) {
            const spaceInfo = getSpaceIndexesForPhoneFormat(
                unformattedValue,
                phoneFormat,
                false
            );
            const newValueAsArray = Array.from(unformattedValue);

            spaceInfo.forEach(spaceIdx => {
                if (spaceIdx < newValueAsArray.length) {
                    newValueAsArray.splice(spaceIdx, 0, ' ');
                }
            });

            return newValueAsArray.join('');
        },

        getUnformattedNumber(
            val,
            phoneFormat,
            didDeleteChars
        ) {
            const updatedSpaceInfo = getSpaceIndexesForPhoneFormat(val, phoneFormat);

            return updatedSpaceInfo
                .reduce((agg, curr, idx) => {
                    if (val[curr] === ' ') {
                        agg.splice(curr - idx, 1);
                    } else if (val[curr + 1] === ' ' && !didDeleteChars) {
                        const offset = 1 - idx;

                        agg.splice(curr + offset, 1);
                    } else if (val[curr - 1] === ' ' && didDeleteChars) {
                        const didDeleteSpace =
            val.split(' ').length - 1 < updatedSpaceInfo.length;

                        if (didDeleteSpace) {
                            agg.splice(curr - idx, 1);
                        } else {
                            agg.splice(curr - idx - 1, 1);
                        }
                    }

                    return agg;
                }, Array.from(val))
                .join('');
        },
        hasNonNumericChar(str) {
            const regex = /[^\d]/;

            return regex.test(str);
        }
    };
});
